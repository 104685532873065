import moment from "moment";
import { getProfileName } from "PFCore/helpers/profile";
import { useBookingInvalidate, useBookingUpdate } from "PFCore/hooks/queries";
import { useBookingsReassign } from "PFCore/hooks/queries/bookings/reassign/use_bookings_reassign";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Activity, Availability, Booking } from "PFTypes";
import { createContext, PropsWithChildren, useContext, useMemo, useReducer } from "react";

import { ReassignSelectOption } from "../../../../components/reassign_booking_modal/bookings_to_reassign_selector";
import { ReassignBookingModal } from "../../../../components/reassign_booking_modal/reassign_booking_modal";
import { ReassignSidePanel } from "../../../../components/reassign_side_panel/reassign_side_panel";
import useRowChildrenInvalidate from "../../side_panel_row/queries/use_row_children_invalidate";

type ReassignActionState = {
  isModalOpened: boolean;
  isSidePanelOpened: boolean;
  data: ReassignActionData | null;
};

const INITIAL_STATE: ReassignActionState = {
  isModalOpened: false,
  isSidePanelOpened: false,
  data: null
};

type ReassignActionData = {
  activityName: string | undefined;
  activity?: Activity;
  sourceProfileId: number;
  targetProfileId?: number;
  booking: Booking;
  isEngagement?: boolean;
  availability?: Availability;
  onClose?: () => void;
  onSubmit?: () => void;
  onError?: () => void;
};

type ReassignActionApi = {
  openModal: (data: ReassignActionData) => void;
  closeModal: () => void;
  openSidePanel: (data: ReassignActionData) => void;
  closeSidePanel: () => void;
};

const INITIAL_API: ReassignActionApi = {
  openModal: () => {},
  closeModal: () => {},
  openSidePanel: () => {},
  closeSidePanel: () => {}
};

type ReassignSubmitApi = {
  onSubmit: (reassignOption: ReassignSelectOption, targetProfileId?: number) => void;
};

const INITIAL_SUBMIT_API: ReassignSubmitApi = {
  onSubmit: () => {}
};

const ReassignActionApiContext = createContext<ReassignActionApi>(INITIAL_API);
const ReassignActionStateContext = createContext<ReassignActionState>(INITIAL_STATE);
const ReassignSubmitApiContext = createContext<ReassignSubmitApi>(INITIAL_SUBMIT_API);

type Action =
  | { type: "openReassignModal"; data: ReassignActionData }
  | { type: "closeReassignModal" }
  | { type: "openReassignSidePanel"; data: ReassignActionData }
  | { type: "closeReassignSidePanel" };

const reassignActionReducer = (state: ReassignActionState, action: Action): ReassignActionState => {
  switch (action.type) {
    case "openReassignModal":
      return { ...state, isModalOpened: true, data: action.data };
    case "closeReassignModal":
      return { ...state, isModalOpened: false, data: null };
    case "openReassignSidePanel": {
      return { ...state, isSidePanelOpened: true, data: action.data };
    }

    case "closeReassignSidePanel":
      return { ...state, isSidePanelOpened: false, data: null };
    default:
      return state;
  }
};

export const ReassignActionContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reassignActionReducer, INITIAL_STATE);

  const { mutateAsync: reassignBooking } = useBookingsReassign({
    options: {
      onError: () => {
        if (state.data?.onError) {
          state.data.onError();
        }
      }
    }
  });

  const { data: targetProfile, isLoading: targetProfileIsLoading } = useProfile(state.data?.targetProfileId, {
    enabled: !!state.data?.targetProfileId
  });

  const { data: sourceProfile, isLoading: sourceProfileIsLoading } = useProfile(state.data?.sourceProfileId, {
    enabled: !!state.data?.sourceProfileId
  });

  const { update: updateBooking } = useBookingUpdate({
    onError: () => {
      if (state.data?.onError) {
        state.data.onError();
      }
    }
  });
  const { invalidateByActivityIds } = useRowChildrenInvalidate();
  const { invalidateProfileBookingsList } = useBookingInvalidate();

  const { formatISODate } = useDateFormatter();

  const apiValue = useMemo<ReassignActionApi>(() => {
    const openModal = (data: ReassignActionData) => {
      dispatch({ type: "openReassignModal", data });
    };

    const closeModal = () => {
      dispatch({ type: "closeReassignModal" });
    };

    const openSidePanel = (data: ReassignActionData) => {
      dispatch({ type: "openReassignSidePanel", data });
    };

    const closeSidePanel = () => {
      dispatch({ type: "closeReassignSidePanel" });
    };
    return { openModal, closeModal, openSidePanel, closeSidePanel };
  }, []);

  const submitApiValue = useMemo(() => {
    const handleSubmit = async (reassignOption: ReassignSelectOption, targetProfileId?: number) => {
      const newProfileId = targetProfileId ?? state.data?.targetProfileId;

      if (state.data?.booking.activity_id && newProfileId !== undefined) {
        if (reassignOption === ReassignSelectOption.ThisBooking) {
          await updateBooking({ id: state.data?.booking.id, profileId: newProfileId }).then(() => {
            state.data?.booking.activity_id && invalidateByActivityIds([state.data.booking.activity_id]);
          });
        } else {
          const min =
            reassignOption === ReassignSelectOption.ThisAndFuture
              ? formatISODate(state.data.booking.start_date)
              : formatISODate(moment().subtract(2, "year"));
          const max = formatISODate(moment().add(2, "year"));

          await reassignBooking({
            activityId: state.data?.booking.activity_id,
            newProfileId,
            oldProfileId: state.data?.sourceProfileId,
            dateRange: {
              min,
              max
            }
          });

          invalidateProfileBookingsList(newProfileId);
          invalidateProfileBookingsList(state.data.sourceProfileId);
        }
      }
      state.data?.onSubmit && state.data.onSubmit();

      state.isModalOpened && apiValue.closeModal();
      state.isSidePanelOpened && apiValue.closeSidePanel();
    };
    return {
      onSubmit: handleSubmit
    };
  }, [state, apiValue]);

  const stateValue = useMemo(() => state, [state]);

  return (
    <ReassignActionApiContext.Provider value={apiValue}>
      <ReassignActionStateContext.Provider value={stateValue}>
        <ReassignSubmitApiContext.Provider value={submitApiValue}>
          {children}
          <ReassignBookingModal
            profileFrom={sourceProfile}
            profileTo={{
              profile: targetProfile
            }}
            isLoading={targetProfileIsLoading || sourceProfileIsLoading}
            readOnlyMode={true}
            shouldDisplayReassignOptions={true}
          />
          <ReassignSidePanel profileFullName={getProfileName(sourceProfile)} />
        </ReassignSubmitApiContext.Provider>
      </ReassignActionStateContext.Provider>
    </ReassignActionApiContext.Provider>
  );
};

export const useReassignActionApi = () => useContext(ReassignActionApiContext);
export const useReassignActionState = () => useContext(ReassignActionStateContext);
export const useReassignSubmitApi = () => useContext(ReassignSubmitApiContext);
