import { decamelizeKeys } from "humps";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useAvailabilityFormatter } from "PFCore/helpers/availability";
import InfoIcon from "PFIcons/icon_info.svg";
import { Activity, AuditRole, AvailabilityRule } from "PFTypes";
import { useMemo } from "react";

import { useReadableAvailability } from "../../../helpers/use_readable_availability";
import css from "./role_availability.module.scss";

type RoleAvailabilityProps = {
  role: AuditRole | Activity;
};

export const RoleAvailability = ({ role }: RoleAvailabilityProps) => {
  // TODO: [SP-2590] It might be that role comes camelized. Remove decamelizeKeys when app is camelized
  const availability = decamelizeKeys(role.availability) as AvailabilityRule;

  const { readableAvailability } = useReadableAvailability({
    availability
  });

  const { availabilityRangesFormat, availabilityIntervalFormat } = useAvailabilityFormatter();

  const tooltipContent = useMemo(() => {
    if (!availability) {
      return null;
    }

    if (availability?.mode === "time_rule") {
      return availabilityRangesFormat(availability, true, css.rangesTooltipContent);
    }

    return availabilityIntervalFormat(availability);
  }, [availability, availabilityIntervalFormat, availabilityRangesFormat]);

  return (
    <>
      {readableAvailability}
      {availability?.ranges && (
        <Tooltip content={<div className={css.tooltipContent}>{tooltipContent}</div>}>
          <span>
            <InfoIcon width={20} height={20} style={{ transform: "translate(0px, 5px)" }} />
          </span>
        </Tooltip>
      )}
    </>
  );
};
