import { isNil } from "lodash";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import css from "./history_updates_attributes.module.scss";

type UpdatedAttributeProps = {
  from?: string | number | null;
  to?: string | number | null;
};

export const UpdatedAttribute = ({ from, to }: UpdatedAttributeProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history.entry.updatedAttributes" });

  const renderValue = (value?: string | number | boolean | null) => (isNil(value) ? "-" : String(value));

  return (
    <div className={css.updateAttributeContainer}>
      <div className={css.updateAttribute}>
        <Typography className={css.updateAttribute} variant="labelRegular" noMargin>
          {t("from")}
        </Typography>
        <Typography className={css.updateAttribute} variant="bodyRegular" noMargin>
          {renderValue(from)}
        </Typography>
      </div>
      <div className={css.updateAttribute}>
        <Typography className={css.updateAttribute} variant="labelRegular" noMargin>
          {t("to")}
        </Typography>
        <Typography className={css.updateAttribute} variant="bodyRegular" noMargin>
          {renderValue(to)}
        </Typography>
      </div>
    </div>
  );
};
